<!--
  系统管理-用户管理
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
	<el-card class="box-card">
	  <div slot="header" class="card-header">
      <h3 class="card-title titleCont" style="padding-left: 0px;">
        接口管理
      </h3>
			<div style="float: left;">
			   <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
			 </div>
			 <div style="float:right;">
			    <el-button type="success"  icon="el-icon-circle-plus-outline"  @click="onAdd()">新增</el-button>
			 </div>
	  </div>
	  <div class="card-body p-0">
	    <el-table stripe :data="item" class="tableTwo table-hover text-nowrap "
	    					style="width: 100%; margin-top: 0px; ">
			  <el-table-column type="index" width="120px;" label="序号"></el-table-column>
			  <el-table-column prop="title" label="标题">
				  <template slot-scope="scope">
					  <span>{{scope.row.title}}</span>
				  </template>
			  </el-table-column>
				<el-table-column prop="interfaceName" label="接口">
				  <template slot-scope="scope">
						<span>{{scope.row.interfaceName}}</span>
				  </template>
				</el-table-column>
				<el-table-column prop="isOnline" label="上线">
				 <template slot-scope="scope">
				 	<el-switch
				 	  v-model="scope.row.isOnline"
				 	  disabled>
				 	</el-switch>
				 </template>
				</el-table-column>
				<el-table-column prop="order" label="排序">
				 <template slot-scope="scope">
				 	<span>{{scope.row.order}}</span>
				 </template>
				</el-table-column>
				<el-table-column prop="lastUpdateTime" label="更新时间">
				 <template slot-scope="scope">
				 	<span>{{scope.row.lastUpdateTime | dateformatMinute}}</span>
				 </template>
				</el-table-column>
				<el-table-column prop="createUser" label="创建人">
				 <template slot-scope="scope">
						<span v-if="scope.row.createUser!=null">
							{{scope.row.createUser.name}}/{{scope.row.createUser.userName}}
						</span>
				 </template>
				</el-table-column>
			  <el-table-column label="操作" width="210px">
				<template slot-scope="scope">
					<operationBtn  :btnName="'编辑'" @click.native="onEdit(scope.row.interfaceId)"></operationBtn>
					<operationBtn v-if="scope.row.userId == currentUser.userId || currentUser.userType==10" :btnName="'删除'" @click.native="onDelete(scope.row.interfaceId)"></operationBtn>
					<operationBtn :btnName="'显示'" @click.native="onDispaly(scope.row.interfaceName)"></operationBtn>
				</template>
			 </el-table-column>
	    </el-table>
	  </div>
		<div style="height: 60px;padding-top:20px;">
			<comPage
				:paging="page"
				:pageBtnList="pageBtnList"
				@pagingClick="pagingClick"
				@pageBtnClick="pageBtnClick">
			</comPage>
		</div>
	</el-card>
  </div>
</template>

<script>
  import comPage from '@/components/page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  export default{
    components:{
      comPage,operationBtn,search
    },
    data(){
      return{
				currentUser:null,
				fullscreenLoading:false,
				page:null,//分页查询接口返回的结果
				item:[], //列表数据
				pageBtnList:null,//分页左侧按钮集合
				paging: { //分页数据
					pageLength: 0, // 总共页数
					Size: 10, // 当前请求数量
					Index: 1 //当前在第几页
				},
				isPower: {

				},
				SearchList: null,//搜索集合
      };
    },
	created()
	{
		let that = this;
		/*isPower(that, "sysuser_details","sysuser_details");
		isPower(that, "sysuser_create","sysuser_create");
		isPower(that, "sysuser_edit","sysuser_edit");
		isPower(that, "sysuser_delete","sysuser_delete");
    isPower(that, "sysuser_range","sysuser_range");
    isPower(that, "sysuser_initPassword","sysuser_initPassword");*/
		that.currentUser = JSON.parse(localStorage.getItem("currentUser"));
		pagingPage(that);
	},
	methods:{
		onDispaly(name)
		{
			let that = this;
			window.open(that.$ServerUrl() + "/api/Jiekou/index?name=" + name, '_blank');
		},
		onSearch(params)
		{
			let that = this;
			let data = {};
			let searchData = params;
			searchData.forEach(element => {
				if (element.data) {
					data[element.zhi] = element.data;
				}
			});
			data.PageNumer = 1;
			routerPath(that, "/Admin/Interface/index", data, null, "PageSize");
		},
		onAdd()//新增
		{
			let that = this;
			// that.$router.push('/Admin/user/Create');
      that.$router.push({
      	path: "/Admin/Interface/Create/",
      	query: { }
      });
		},
		onDetail(Id)//查看
		{
			let that = this;
			that.$router.push({
				path: "/Admin/Interface/Detail/" + Id,
				query: { url: that.$router.history.current.fullPath }
			});


		},
		onEdit(Id)//编辑
		{
			let that = this;
			that.$router.push({
				path: "/Admin/Interface/Edit/" + Id,
				query: { url: that.$router.history.current.fullPath }
			});
		},
		onDelete(Id) //删除
		{
			let that = this;
			confirmDelete(that,null,function(res){
				if(res == true)
				{
					that.fullscreenLoading = true;
					ajaxDelete(that,"/api/admin/sysInterface/Remove/" + Id,null,function(r){
						that.fullscreenLoading = false;
						pagingPage(that);
					});
				}
			})
		},

		pageBtnClick(index) {
			//分页组件左侧的按钮事件，按顺序进行判断
		},
		pagingClick(type, val) {
			let that = this;
			let url = "/Admin/Interface/index";
			if (type == 1) {//更改每页条数触发的事件
				routerPath(
					that,
					url,
					"PageSize",
					val,
					"PageNumer",
					"Name",

				);
			} else { //更改当前页时触发的事件
				routerPath(
					that,
					url,
					"PageNumer",
					val,
					"PageSize",
					"Name",
				);
			}
		},
	},
	watch: {
		$route() {
			let that = this;
			pagingPage(that);
		}
	}
  };

 function pagingPage(that) {
	  paging(
		  that,
		  function (r) {
			  let Myurl = "/api/admin/sysInterface/getPage";
			  let data = {
				  PageNumer: r.PageNumer,
				  PageSize: r.PageSize,
				  Name: r.Name,
			  };

			  that.SearchList = [
				  {
					  type: "input",
					  Name: "标题",
					  data: r.Name,
					  holder: "请输入标题",
					  zhi: "Name"
				  },
			  ];
			  that.fullscreenLoading = true;
			  ajaxGet(that, Myurl, data, function (r) {
					console.log(r);
				  that.page = r;
				  that.item = r.items;
				  that.fullscreenLoading = false;
			  });
		  },
		  "Name",
	  );
  }
</script>

<style scoped="scoped">

</style>
